import project1 from "../Assets/project1.jpg";
import fondationAksanti from "../Assets/fondationAksantiReact.jpg";
import restaurant from "../Assets/restaurant.jpg";
import bmiCalculator from "../Assets/bmiCalculator.jpg";
import eatHealthy from "../Assets/xdHealthy.jpg";
import workit from "../Assets/workit.jpg";
import advicegenerator from "../Assets/advicegenerator.jpg";

const Database = [
  {
    id: 1,
    rank: 1,
    title: "Fondation Aksanti Kabuzi",
    description:
      " This website is dedicated to the mission of a foundation that strives to assist individuals in need through various initiatives and programs.",
    projectImage: fondationAksanti,
    category: "React",
    link: "https://harmonious-douhua-6e2dfa.netlify.app",
  },
  {
    id: 2,
    rank: 2,
    title: "Toni Market",
    description:
      " This React application serves as a basic demonstration of a shoe store, featuring fundamental functionalities. It allows users to browse and interact with the available shoe inventory. Key aspects include showcasing products, filtering and sorting options.Overall, it offers a user-friendly interface for exploring and purchasing shoes, although it may lack some advanced features seen in more comprehensive e-commerce ",
    projectImage: project1,
    category: `React`,
    link: "https://soft-elf-952e2e.netlify.app",
  },
  {
    id: 3,
    rank: 1,
    title: "BMI Calculator",
    description:
      " This singular webpage demonstrates the process of calculating body mass index (BMI) using height and weight, presented in both conventional units and alternative measurements such as feet and stones.",
    projectImage: bmiCalculator,
    category: `Js`,
    link: "https://courageous-bonbon-0843df.netlify.app",
  },
  {
    id: 4,
    rank: 2,
    title: "Chakula restaurant",
    description:
      "This website represents a dining establishment, showcasing its menu for patrons to peruse and select meals to add to their order. Additionally, it offers convenient access to contact information and an informative 'about' page. The site is enhanced with engaging animations to captivate visitors.",
    projectImage: restaurant,
    category: `Js`,
    link: "https://illustrious-crepe-c10ed8.netlify.app",
  },
  {
    id: 5,
    rank: 1,
    title: "Workit",
    description:
      "This project entails the creation of a singular landing page designed to showcase an application. Utilizing fundamental HTML and CSS (Sass), the webpage meticulously arranges various components to offer a lucid presentation.",
    projectImage: workit,
    category: `Xd`,
    link: "https://gorgeous-meringue-317e1c.netlify.app",
  },
  {
    id: 6,
    rank: 2,
    title: "Healthy",
    description:
      "This landing page showcases eating habits and demonstrates proficiency in HTML and CSS, with the additional use of Sass for styling.",
    projectImage: eatHealthy,
    category: `Xd`,
    link: "https://earnest-pony-094f7f.netlify.app",
  },

  {
    id: 7,
    rank: 3,
    title: "Advice Generator",
    description:
      " This app generates random advice from an external API with a single click on the dice button. It displays the advice along with the author's name for added context.",
    projectImage: advicegenerator,
    category: `React`,
    link: "https://magical-basbousa-00d554.netlify.app/",
  },
];
export default Database;
