const colors = {
  primary: "#1f252f",
  primaryTinted: "#232a37",
  secondary: "#eeeeee",
  secondaryTinted: "#e6e6e6",
  circleWhite: "#dadada",
  circleDark: "#373a40",
  secondaryYellow: "#e6b419",
  primaryPurpule: "#5f268a",
  primaryPurpuleHover: "#7e36b6",
  cssColor: "#619ae8",
  htmlColor: "#c1590e",
  javascriptColor: "#e6b419",
  paragraphColorWhite: "#bfbfbf",
  paragraphColorDark: "#626262",
  gradient: " linear-gradient(to right, #b49263, #5f268a)",
  dayColor: "white",
  nightColor: "#626262",
};
export default colors;
